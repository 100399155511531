// Generated by Framer (4175fb7)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mICAfyCHT"];

const serializationHash = "framer-FObNM"

const variantClassNames = {mICAfyCHT: "framer-v-svhqtl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mICAfyCHT", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "", fit: "fit", intrinsicHeight: 1503, intrinsicWidth: 2249, pixelHeight: 1503, pixelWidth: 2249, positionX: "center", positionY: "center", sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png", srcSet: "https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=512 512w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/jeM9vFpQoUFywxtav4k9NlexeeI.png 2249w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-svhqtl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mICAfyCHT"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FObNM.framer-13hdkoo, .framer-FObNM .framer-13hdkoo { display: block; }", ".framer-FObNM.framer-svhqtl { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 263px; justify-content: center; padding: 0px 0px 0px 0px; position: relative; width: 350px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-FObNM.framer-svhqtl { gap: 0px; } .framer-FObNM.framer-svhqtl > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-FObNM.framer-svhqtl > :first-child { margin-top: 0px; } .framer-FObNM.framer-svhqtl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 263
 * @framerIntrinsicWidth 350
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerHqVhFudjh: React.ComponentType<Props> = withCSS(Component, css, "framer-FObNM") as typeof Component;
export default FramerHqVhFudjh;

FramerHqVhFudjh.displayName = "Logo";

FramerHqVhFudjh.defaultProps = {height: 263, width: 350};

addFonts(FramerHqVhFudjh, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})